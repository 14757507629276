import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import Accordion from "../../components/Accordion"
import DHA from "../../images/static-only/ingredients/DHA_2.jpg"
import DHAHeader from "../../images/static-only/ingredients/dha-header.jpg"
import MFGMBody from "../../images/static-only/ingredients/mfgm-body.jpg"
import Lactoferrin from "../../images/static-only/ingredients/Lactoferrin.jpg"
import LactoferrinHeader from "../../images/static-only/ingredients/lactoferrin-header.jpg"
// import Inactivity from "../../components/Inactivity"

class AccordionListing extends React.Component {
  componentDidMount() {
    // Inactivity()
    Accordion()
    document.body.classList.remove('-nav-active')
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - Learn About Key Ingredients</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={DHA} alt="Toddler eating food" />

            <div className="hero-area__inner">
              <h1>Learn About Key Ingredients</h1>
              <nav className="breadcrumb" role="navigation">
                  <Link to="/event-experience" title="Event Experience">
                    <i className="fas fa-arrow-left"></i>
                    Event Experience
                  </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/event-experience" title="Event Experience">
                <i className="fas fa-arrow-left"></i>
                Event Experience
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">Overview</div>
              <div className="accordion__content">
                <img src={Lactoferrin} alt="Toddler eating food" />                             
                <div className="rich-text">
                  <p>It is well known that good nutrition during infancy, along with different forms of stimulation, influences various aspects of mental development. Moreover, if certain micronutrient deficiencies or early nutritional insults occur, development can be compromised. Brain growth and development is especially rapid during the first 2 years of life, and nutrition is vital to support these events.</p>

                  <p>Nutrition plays a critical role in helping to establish gastrointestinal (GI) function in the neonate. Human milk is uniquely suited to provide the necessary nutrients to establish not only digestion, absorption, and gut barrier function, but also the development of the intestinal microflora and immune tolerance. It is clear that the development of the GI microbiota begins at birth, which is also a critical time period for brain development. Emerging research suggests that the gut microbiota, and possibly the nutrients that support the microbiota, may play a role in brain maturation and support later brain functioning.</p>

                  <p>Human breast milk is clearly the “gold standard” in terms of providing optimal nutrition for the developing infant.</p>

                  <p>You can read about key ingredients<sup>*</sup> by clicking each section to learn more about their benefits in supporting your child’s development. </p>

                  <p><em><sup>​*</sup>​*Disclaimer: Not all Mead Johnson formulas contain MFGM, DHA and lactoferrin</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">DHA</div>
              <div className="accordion__content">
                <img src={DHAHeader} alt="DHA Header" />                
                <div className="rich-text">
                  <p>Shorthand for docosahexaenoic acid, DHA is an omega-3 fatty acid and is found in a variety of food sources. Once toddlers outgrow breast milk or infant formula, both of which have DHA, their intake of DHA may drop.</p>
                  <p>Dietary intakes of alpha-linolenic acid (ALA), docosahexaenoic acid (DHA), iron, vitamin D and iodine (in some European countries) are low in infants and young children living in Europe, and particular attention should be paid to ensuring an appropriate supply of ALA, DHA, iron, vitamin D and iodine in infants and young children with inadequate or at risk of inadequate status of these nutrients.</p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">MFGM</div>
              <div className="accordion__content">
                <img src={LactoferrinHeader} alt="" />                
                <div className="rich-text">
                  <p>Milk Fat Globule Membrane (MFGM) is the membrane surrounding the secreted fat droplets in milk. It is released by a unique mechanism in the mammary gland and is composed of a triple phospholipid and cholesterol layer with incorporated proteins and glycoproteins . MFGM is one of the most important components of human milk.</p>
                  <img src={MFGMBody} alt="MFGM molecule" /> 
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Lactoferrin</div>
              <div className="accordion__content">
                <img src={LactoferrinHeader} alt="" />
                <div className="rich-text">
                  <p>Lactoferrin is an iron-binding protein present in large quantities in colostrum and in breast milk, belonging to the transferrin protein family. </p>
                </div>
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default AccordionListing